<template>
    <div id="checkout-container">
        <div class="checkout-content">
            <div class="checkout-left">
                <div class="checkout-left-content">
                <div class="item-title">Contact Information</div>
                <input type="text" placeholder="Email">
                <div class="customer-info-content">
                    <div class="item-title">Shipping Address</div>
                    <div class="customer-info-item name-info">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <input type="text" placeholder="First Name" v-model="customerData.firstName">
                            </el-col>
                            <el-col :span="12">
                                <input type="text" placeholder="Last Name" v-model="customerData.lastName">
                            </el-col>
                        </el-row>
                        
                    </div>
                    <div class="customer-info-item company">
                        <input type="text" placeholder="Company" v-model="customerData.company">
                    </div>
                    <div class="customer-info-item address">
                        <input type="text" placeholder="Address" v-model="customerData.address1">
                    </div>
                    <div class="customer-info-item city">
                        <input type="text" placeholder="City" v-model="customerData.city">
                    </div>
                    <div class="customer-info-item country">
                        <el-row :gutter="10">
                            <el-col :span=gutter>
                                <el-select v-model="customerData.country" placeholder="Country/Area" @change="countryChange" no-data-text="empty">
                                    <el-option
                                    v-for="item in countryData"
                                    :key="item.Name"
                                    :label="item.Name"
                                    :value="item.Name">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span=gutter >
                                <el-select v-model="customerData.province" placeholder="Province" no-data-text="empty" :disabled=stateShow>
                                    <el-option
                                    v-for="item in stateData"
                                    :key="item.Name"
                                    :label="item.Name"
                                    :value="item.Name">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span=gutter>
                                <input type="text" placeholder="Zip" v-model="customerData.zip">
                            </el-col>
                            
                        </el-row>
                    </div>
                    <div class="customer-info-item phone">
                        <input type="text" placeholder="Phone" v-model="customerData.phone">
                    </div>
                </div>
                <div class="shipping-info-content">
                    <div class="item title">Shipping Methods</div>
                    <div v-show="!hasShipping">
                        after select country will show
                    </div>
                    <div v-show="hasShipping">
                        <div class="radio-item" v-for="i in shippingList" :key="i.id">
                            <el-radio v-model="shipping" :label="i.id" border @change="shippingChange">
                                <span class="radio-content">
                                    {{i.planName}}
                                    <span>   ${{i.shippingMony}}</span>
                                </span>
                            </el-radio>
                        </div>
                        
                    </div>
                </div>
                <div class="payment-info-content">
                    <div class="item-title">Payment Method</div>
                    <div class="item-info">Select the address that matches your card or payment method.</div>
                    <div class="radio-item">
                        <el-radio v-model="paymentMethods" label="1" border>
                            credit card
                            <div class="stripe-content">
                                <input placeholder="Card Number" class="number" type="Number" v-model="paypalInfo.number">
                                <input @input="changeTime" placeholder="MM/YY" class="time" type="text" v-model="paypalInfoTime">
                                <input placeholder="CVC" class="cvc" type="Number" v-model="paypalInfo.cvc">
                            </div>
                        </el-radio>
                        <el-radio v-model="paymentMethods" label="2" border>
                            paypal
                        </el-radio>
                    </div>
                </div>
                <div class="action-btn">
                    <el-button type="primary" @click="pay">Pay</el-button>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="payDialogShow"
            width="60%"
        >
        <iframe scrolling="auto" :src=targetUrl frameborder="0" width="100%" height="700px"></iframe>
        </el-dialog>
            <div class="checkout-right">
                <div class="checkout-right-content">
                    <div class="cart-list-item" v-for="i in cartItems" :key="i.variantId">
                        <div class="img-box">
                            <img :src="i.image" alt="">
                            <div class="count">{{i.quantity}}</div>
                        </div>
                        <div class="product-info">
                            <div class="title">{{i.title}}</div>
                            <div class="sku">sku: {{i.sku}}</div>
                        </div>
                        <div class="price">${{i.price}}</div>
                    </div>
                    <div class="subtotal">
                        <div class=subtotal-item>
                            <div class="title">Total weight</div>
                            <div class="val">{{totalCart.totalWeight}}{{totalCart.weightUnit}}</div>
                        </div>
                        <div class=subtotal-item>
                            <div class="title">Total Price</div>
                            <div class="val">${{totalCart.subTotal}}</div>
                           
                        </div>
                        <div class=subtotal-item>
                            <div class="title">Shipping</div>
                            <div class="val"  v-if="totalCart.totalShipping>0">${{totalCart.totalShipping}}</div>
                             <div class="val" v-else>Calculate after choose shipping methods</div>
                        </div>
                    </div>
                    <div class="total">
                        <div class="total-item">
                            <div class="title">Total</div>
                            <div class="val">${{totalCart.totalPrice}}</div>
                        </div>
                    </div>
                </div>  
                
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions , mapGetters} from 'vuex'
import {cityData} from '@/assets/city.js'
import {findAll , getShipping ,caculateCartPrice ,payWithPaypal,payWithStripe} from '@/api/mall/index.js'
export default {
    data () {
        return {
            paymentMethods:"1",            
            gutter:8,
            stateShow:false,
            hasShipping:false,
            countryData:[],
            stateData:[],
            customerData:{
                firstName:'',
                lastName:'',
                address1:'',
                phone:'',
                city:'',
                zip:'',
                province:'',
                country:'',
                company:'',
                email:''
            },
            shippingList:[],
            areaInfo:[],
            radio:'',
            shipping:'',
            totalCart:{
                productList:[],
                subTotal:'',
                totalPrice:'',
                totalWeight:'',
                weightUnit:'',
                totalShipping:''
            },
            payDialogShow:false,
            targetUrl:'',
            paypalInfoTime:'',
            paypalInfo:{
                number:'',
                month:'',
                year:'',
                cvc:'',
                email:''

            }      
        }
    },
    computed:{
        ...mapGetters([
            "weight",
            "cartItems",
            "userId"
        ])
    },
    created(){
        this.changeHeaderShow({
            status:false
        })
        this.updateCart()
    },
    destroyed(){
        this.changeHeaderShow({
            status:true
        })
    },
    mounted(){
        this.findCountry()
    },
    methods:{
        countryChange(){            
            let selectState = this.countryData.filter(item=>{                
                return item.Name == this.customerData.country
            })[0]
            // 国家 省 联动
            if(selectState.State && selectState.State.length>1){                               
                this.stateShow = false
                this.stateData = selectState.State
            }
            else if(selectState.State && selectState.State.City){                               
                this.stateShow = false
                this.stateData = selectState.State.City
            }else{                
                this.stateShow = true
                this.customerData.province = ''
            }
            // 获取选中国家的运费
            let shippingData = this.areaInfo.filter((item)=>{
                return item.Code == selectState.Code
            })[0]            
            this.getShipping(shippingData.id)
            

        },
        getShipping(id){
            getShipping(JSON.stringify({id:id,weight:this.weight})).then(res=>{
                this.shippingList = res.data
                this.hasShipping = true
            })
        },
        findCountry(){
            findAll().then(res=>{
                let resData = res.data
                this.areaInfo = resData
                // 获取返回城市的信息
                this.countryData = cityData.Location.CountryRegion.filter((item)=>{                    
                    var country = resData.filter(val => {
                        return item.Code == val.Code
                    });
                    return country.length > 0                                        
                })

            })
        },
        updateCart(id){
            let  data = {
                detailId:id,
                productList:this.cartItems
            }            
            caculateCartPrice(JSON.stringify(data)).then(res=>{
                this.totalCart = res.data.cart
            })
        },
        shippingChange(){            
            this.updateCart(this.shipping)

        },
        changeTime(e){            
            let inputNumber = this.paypalInfoTime
            if(!(e.data>-1)){ 
                this.paypalInfoTime = ''
                return
            }
            if(inputNumber.length == 1 && inputNumber < 10 && inputNumber!=1 && inputNumber>0){ 
                this.paypalInfoTime = 0 + this.paypalInfoTime
            }
            if(inputNumber.length == 2){
                if(inputNumber>12){
                    this.paypalInfoTime = 12
                }
                this.paypalInfoTime = this.paypalInfoTime + ' / '
            }
            if(inputNumber.length > 7){
                this.paypalInfoTime = this.paypalInfoTime.substring(0,7)
            }
        },
        pay(){
            
            if(this.paymentMethods == 1){               
                let [month,year] = this.paypalInfoTime.split(' / ')
                this.paypalInfo.email = this.customerData.email
                this.paypalInfo.month = month
                this.paypalInfo.year = year
                let data = {
                    lineItems:this.cartItems,
                    shippingAddress:this.customerData,
                    detailId:this.shipping,
                    stripe:this.paypalInfo,
                    customerId:this.userId
                }
                console.log(data)
                payWithStripe(JSON.stringify(data)).then(res=>{
                    this.changeCart({
                        cartItems:[]
                    })           
                })              
            }else if(this.paymentMethods == 2){
                let data = {
                    lineItems:this.cartItems,
                    shippingAddress:this.customerData,
                    detailId:this.shipping,
                    customerId:this.userId
                }
                payWithPaypal(JSON.stringify(data)).then(res=>{
                    window.location.href=res.link    
                    this.changeCart({
                        cartItems:[]
                    })                               
                })
            }
        },
        ...mapActions([
            'changeHeaderShow',
            'changeCart'
        ])
    }
}
</script>

<style lang="stylus" scoped>
#checkout-container
    color #222
    background #fff
    height 100%
    .checkout-content
        display flex
        height 100%
    .checkout-left
        flex 1
        display flex
        justify-content flex-end
        padding-top 3.125rem
        padding-right 1.5625rem
        .checkout-left-content
            max-width 37.25rem
            .item-title
                font-size 1rem
                color #666
                margin-bottom 1.25rem
            input
                width 100%
                border 1px solid #ddd
                height 2.5rem
                line-height 2.5rem
                font-size .875rem
                color #222
                padding-left .9375rem
                box-sizing border-box
                border-radius .375rem
            .customer-info-content
                margin-top 1.875rem
            .customer-info-item
                margin-bottom .625rem
    .checkout-right
        flex 1
        display flex
        justify-content flex-start
        border-left 1px solid #efefef
        background #fafafa
        padding-top 3.125rem
        padding-left 1.5625rem
        .checkout-right-content
            max-width 37.25rem
.shipping-info-content
    .item.title
        margin-top 1.25rem
        margin-bottom 1.25rem
    .radio-item
        margin-bottom .625rem
    label.el-radio.is-bordered
        width 100%
.cart-list-item
    padding 1.25rem 0
    border-bottom 1px solid #efefef
    display flex
    align-items center
    .price
        font-size .875rem
        padding-left 2.5rem
    .img-box
        position relative
        img 
            width 3.75rem
        .count 
            position absolute
            min-width 1.375rem
            height 1.375rem
            text-align center
            line-height 1.375rem
            font-size .75rem
            background-color #999
            border-radius 50%
            right .3125rem
            top -.6875rem
            color #fff
    
.product-info 
    font-size .875rem
    color #222
    padding-left .625rem
    .price 
        font-size .75rem
        font-weight 600
        padding-left 1.25rem
    .sku 
        margin-top .625rem
        font-size .75rem
        color #666
.subtotal-item,.total-item
    width 100%  
    display: flex
    justify-content: space-between
    margin-bottom .5rem
    font-size 14px
.total
    padding 1.25rem 0
.subtotal 
    padding 1.25rem 0
    border-bottom 1px solid #efefef
.payment-info-content
    .item-title
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        color #222
    .item-info
        color #999999
        font-size .8125rem
        margin-bottom .625rem
    .el-radio.is-bordered
        width 100%
        margin-left 0
        margin-bottom .625rem
        min-height 2.5rem
        height auto
    .action-btn
        text-align right
    .stripe-content
        display flex
        padding 1.25rem 0 .625rem 0
        input.time
            width 5rem !important
            margin 0 .625rem
        input.cvc
            width 5rem !important
    
    

</style>
